/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: #121212;
}

.link {
  // color: #408bf9;
  color: #2d6c71;
  cursor: pointer;
  text-decoration: underline;
}

table {
  border-collapse: separate;
}

.divider {
  width: 100%;
  height: 1px;
  border-top: 1px solid #cecece;
  margin: 24px 0;
}
// For IBM Accessibility
// .mat-focus-indicator:focus {
//   outline: 2px solid #2d6c71 !important;
//   border-radius: 2px !important;
// }

// .mat-select:focus{
//   outline: 2px solid #2d6c71 !important;
//   border-radius: 2px !important;
// }
// .mat-input-element:focus {
//   outline: 2px solid #2d6c71 !important;
//   border-radius: 2px !important;
// }
// .cdk-visually-hidden {
//   display: none !important;
// }
// .cdk-overlay-backdrop.cdk-overlay-backdrop-showing{
//   opacity: 0 !important;
//   background: transparent !important;
// }
.cdk-visually-hidden.cdk-focus-trap-anchor {
  display: none;
}
.mat-ripple {
  display: none;
}